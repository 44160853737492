import React, { useContext } from 'react'
import ThemeContext from '../../context/theme-context'
import Layout from '../../components/layout'
import PortfolioHeader from '../../components/portfolio-header'
import { graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import Timeline from '../../components/timeline'
import Recommendation from '../../components/recommendation'
import ScrollTop from '../../components/scroll-top'

const GamerDNAPage = (props) => {
  const theme = useContext(ThemeContext);
  const themedCompanyLogo = theme.dark ? props.data.companyLogoDarkTheme.childImageSharp.gatsbyImageData : props.data.companyLogo.childImageSharp.gatsbyImageData

  return (
    <Layout>
      <Timeline />
      <article className='Portfolio Portfolio--gamerdna'>
        <section className='Portfolio__intro'>
          <PortfolioHeader name='gamerDNA' position='Front-end Developer' startDate='2008-09' endDate='2009-10-31' logo={themedCompanyLogo} />
          <p className='CompanyIntro'>
            gamerDNA was a platform for gamers. It was a place to build your gaming identity and community across platforms. gamerDNA provided a social experience enabled through tracking, aggregation, and sharing of gameplay. As well as tools to help users discover new games by matching a player’s unique interests.
          </p>
        </section>
        <section className='Portfolio__role'>
          <h2 className='SectionTitle'>My role</h2>
          <p>
            gamerDNA was my first full-time role as a front-end developer. I was brought onto the team to handle the front-end implementation of designs and maintain the user interface through the use of HTML, CSS/Sass, JavaScript, and Ruby&nbsp;on&nbsp;Rails. Manage cross-browser compatibility and improve SEO.
          </p>
        </section>
        <section className='Portfolio__recommendations'>
          <h2 className='SectionTitle'>Recommendations</h2>
          <Recommendation name='Jon Radoff' date='2009' position='Founder/CEO of gamerDNA'>
            We were mired in a very inefficient process for building the GamerDNA user interface... until Collin joined the company. He’s a crackerjack with AJAX, CSS, cross-browser markup and Ruby on Rails. His strong sense of design and implementation makes him the perfect glue between visual U/X designers and the database engineering team. Collin amplifies the effectiveness of any team. Highly recommended!
          </Recommendation>
          <Recommendation name='Trapper Markelz' date='2009' position='VP, Products at gamerDNA'>
            I hired Collin right out of college. From the moment he started he had a huge impact on the team. Not only did he excel at his job of transforming PSD files into cross-browser compatible HTML using CSS, but he continually expanded his role into the areas of JavaScript, AJAX, and Ruby/Rails code. He really grew as an engineer, absorbing the knowledge of those around him, becoming a true expert on the team.
          </Recommendation>
          <Recommendation name='Sean Duhame' date='2009' position='Creative Director at gamerDNA'>
            Collin came to work at gamerDNA with very little professional experience. If I hadn’t directly been responsible for hiring him I would have never know this because of extremely professional and respectful manner in which he worked.
            <br/><br/>
            Collin was hired to own the implementation of user experience designs using a number of front end technologies. He was tasked to work within a new framework he quickly learned and owned, which included a completely new language for him - Ruby. He soon gained the confidence of everyone around him that he had the chops and was the man for the job. After only a few months at gamerDNA the dialogue between myself and Collin about implementing UI’s became very efficient as he became to intrinsically understand what the construction of each UI relied upon. he brought a level of efficiency to the team that more than doubled our ability to act on our project pipeline.
            <br/><br/>
            Collin was also always a part of the design discussion at gamerDNA. He offered objective thoughts only when he knew they would be impactful and lead the discussion toward a more positive outcome. Many of those thoughts and ideas exist on gamerDNA.com today.
            <br/><br/>
            As a co-worker Collin is wonderful to have around. Helpful, positive, easy-going and fun loving are all qualities that he possesses. He’s been a joy to work with.
          </Recommendation>
          <Recommendation name='Russell Miner' date='2009' position='Principal, Lead Engineer at gamerDNA'>
            Collin is a master in the internet world. He is the kind of engineer who can take a sketch of a very complex UI, and then make it into a reality, and all in short order. He is a master at HTML/CSS and his ability to learn underlying languages and technologies is clearly something that comes easy to him. This allows him to get his hands dirty in underlying code (such as he did with Ruby on Rails at gamerDNA), and this in turn frees up engineering resources to concentrate on deeper and more complex systems.
            <br/><br/>
            Any company that has Collin on their team should consider themselves lucky.
          </Recommendation>
        </section>
        <section className='Portfolio__examples'>
          <h2 className='SectionTitle SectionTitle__gallery'>Work examples</h2>
          <div className='Gallery'>
            <div className='Gallery__item'>
              <GatsbyImage
                className='GalleryImg'
                image={props.data.splashLoggedOutScreenshot.childImageSharp.gatsbyImageData}
                alt=''
              />
            </div>
            <div className='Gallery__item'>
              <GatsbyImage
                className='GalleryImg'
                image={props.data.splashLoggedInScreenshot.childImageSharp.gatsbyImageData}
                alt=''
              />
            </div>
            <div className='Gallery__item'>
              <GatsbyImage
                className='GalleryImg'
                image={props.data.homeScreenshot.childImageSharp.gatsbyImageData}
                alt=''
              />
            </div>
            <div className='Gallery__item'>
              <GatsbyImage
                className='GalleryImg'
                image={props.data.profileScreenshot.childImageSharp.gatsbyImageData}
                alt=''
              />
            </div>
            <div className='Gallery__item'>
              <GatsbyImage
                className='GalleryImg'
                image={props.data.profileGamesScreenshot.childImageSharp.gatsbyImageData}
                alt=''
              />
            </div>
            <div className='Gallery__item'>
              <GatsbyImage
                className='GalleryImg'
                image={props.data.profileMediaScreenshot.childImageSharp.gatsbyImageData}
                alt=''
              />
            </div>
            <div className='Gallery__item'>
              <GatsbyImage
                className='GalleryImg'
                image={props.data.gameImageScreenshot.childImageSharp.gatsbyImageData}
                alt=''
              />
            </div>
            <div className='Gallery__item'>
              <GatsbyImage
                className='GalleryImg'
                image={props.data.profileActivityScreenshot.childImageSharp.gatsbyImageData}
                alt=''
              />
            </div>
            <div className='Gallery__item'>
              <GatsbyImage
                className='GalleryImg'
                image={props.data.dnaGamesScreenshot.childImageSharp.gatsbyImageData}
                alt=''
              />
            </div>
            <div className='Gallery__item'>
              <GatsbyImage
                className='GalleryImg'
                image={props.data.gamePageScreenshot.childImageSharp.gatsbyImageData}
                alt=''
              />
            </div>
            <div className='Gallery__item'>
              <GatsbyImage
                className='GalleryImg'
                image={props.data.gamePageTwoScreenshot.childImageSharp.gatsbyImageData}
                alt=''
              />
            </div>
            <div className='Gallery__item'>
              <GatsbyImage
                className='GalleryImg'
                image={props.data.gamePageThreeScreenshot.childImageSharp.gatsbyImageData}
                alt=''
              />
            </div>
            <div className='Gallery__item'>
              <GatsbyImage
                className='GalleryImg'
                image={props.data.gamesDirectoryScreenshot.childImageSharp.gatsbyImageData}
                alt=''
              />
            </div>
          </div>
        </section>
        <ScrollTop />
      </article>
      <Timeline />
    </Layout>
  );
}

export default GamerDNAPage

export const pageQuery = graphql`
  query {
    companyLogo: file(relativePath: { eq: "logos/gamerdna.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          width: 300
        )
      }
    },
    companyLogoDarkTheme: file(relativePath: { eq: "logos/gamerdna-dark-bg.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          width: 300
        )
      }
    },
    splashLoggedOutScreenshot: file(relativePath: { eq: "screenshots/gamerdna/01-splash-logged-out.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          width: 800,
          placeholder: BLURRED
        )
      }
    },
    splashLoggedInScreenshot: file(relativePath: { eq: "screenshots/gamerdna/02-splash-logged-in.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          width: 800,
          placeholder: BLURRED
        )
      }
    },
    homeScreenshot: file(relativePath: { eq: "screenshots/gamerdna/03-home.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          width: 800,
          placeholder: BLURRED
        )
      }
    },
    profileScreenshot: file(relativePath: { eq: "screenshots/gamerdna/04-profile.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          width: 800,
          placeholder: BLURRED
        )
      }
    },
    profileGamesScreenshot: file(relativePath: { eq: "screenshots/gamerdna/05-profile-games.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          width: 800,
          placeholder: BLURRED
        )
      }
    },
    profileMediaScreenshot: file(relativePath: { eq: "screenshots/gamerdna/06-profile-media.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          width: 800,
          placeholder: BLURRED
        )
      }
    },
    gameImageScreenshot: file(relativePath: { eq: "screenshots/gamerdna/07-game-image.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          width: 800,
          placeholder: BLURRED
        )
      }
    },
    profileActivityScreenshot: file(relativePath: { eq: "screenshots/gamerdna/08-profile-activity.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          width: 800,
          placeholder: BLURRED
        )
      }
    },
    dnaGamesScreenshot: file(relativePath: { eq: "screenshots/gamerdna/09-dna-games.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          width: 800,
          placeholder: BLURRED
        )
      }
    },
    gamePageScreenshot: file(relativePath: { eq: "screenshots/gamerdna/10-game-page-1.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          width: 800,
          placeholder: BLURRED
        )
      }
    },
    gamePageTwoScreenshot: file(relativePath: { eq: "screenshots/gamerdna/11-game-page-2.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          width: 800,
          placeholder: BLURRED
        )
      }
    },
    gamePageThreeScreenshot: file(relativePath: { eq: "screenshots/gamerdna/12-game-page-3.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          width: 800,
          placeholder: BLURRED
        )
      }
    },
    gamesDirectoryScreenshot: file(relativePath: { eq: "screenshots/gamerdna/13-games-directory.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          width: 800,
          placeholder: BLURRED
        )
      }
    }
  }
`
